import { createTheme } from '@mui/material/styles';

const colorPrimaryMain = '#1B4985';
// CSI Color
// const colorPrimaryTint = '#da6254';
const colorPrimaryTint = '#bac8da';
// CSI Color
// const colorPrimaryShade = '#8e1607';
const colorSecondaryMain = '#f3f5f5';
const colorSecondaryTint = '#d7dede';
// const colorSecondaryShade = '#8A9191';
const colorTertiaryMain = '#000000';
const colorChatbotUserMain = '#8dbec2'
const colorChatbotUserTint = '#e8f2f2'

const bveTheme = createTheme({
    components: {
        MuiFab: {
            styleOverrides: {
                root: {
                    right: 40,
                    bottom: 15,
                    position: 'fixed'
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                bot: {
                    backgroundColor: colorPrimaryTint,
                    color: '#000000'
                },
                user: {
                    backgroundColor: colorChatbotUserMain,
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    switch (ownerState.variant) {
                      case "contained":
                        return {
                          "&.Mui-disabled": {
                            background: colorChatbotUserTint,
                            // color: "#dadada"
                          }
                        };
                    //   case "outlined":
                    //     return {
                    //       "&.Mui-disabled": {
                    //         background: "#e9e9e9",
                    //         color: "#c7c7c7",
                    //         borderColor: "#e4e4e4"
                    //       }
                    //     };
                      default:
                        return;
                    }
                }
            }
        }
    },
    palette: {
        primary: {
            main: colorPrimaryMain
        },
        secondary: {
            main: colorSecondaryMain,
            tint: colorSecondaryTint
        },
        tertiary: {
            main: colorTertiaryMain
        }
    },
    logo: {
        width: 188,
        height: 45
    }
});

export default bveTheme;