import React from 'react';
import { Tab, Tabs, AppBar, Grid, Toolbar, IconButton, Typography, Box, Modal } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const Logo = styled('img')(({ theme }) => ({
    width: theme.logo.width,
    height: theme.logo.height,
}));


export function Header() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);
    const navigate = useNavigate();

    const location = useLocation();
    let pathname = location.pathname;
    if(pathname === "/") {
        pathname = "/deductions";
    }

    function logOut() {
        signOut();
        // navigate('/login');
        navigate('/login', { state: { from: { pathname: pathname} } });
    }

    // const [currentTab, setCurrentTab] = React.useState(0);
    const [currentTab, setCurrentTab] = React.useState(pathname);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (event, value) => {
        setCurrentTab(value);
    };

    const renderLogout = () => {
        return (
            <Grid item>
            <IconButton
                size="large"
                edge="end"
                color="primary"
                aria-label="logout"
                onClick={() => logOut()}
            >
                <LogoutIcon />
            </IconButton>
            </Grid> 
        );
    }

    const handleMenuItemClick = (value) => {
        setCurrentTab(value);
        setAnchorEl(null);
    };

    const renderMenu = () => {
        return (
            <Grid xs={1} item>
                <IconButton
                    size="large"
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    sx={{ mr:  0 }}
                    onClick={handleMenu}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        component={RouterLink}
                        to="/deductions"
                        onClick={() => handleMenuItemClick("/deductions")}
                        selected={currentTab === "/deductions"}
                    >
                        Deductions
                    </MenuItem>
                    <MenuItem 
                        component={RouterLink}
                        to="/history"
                        onClick={() => handleMenuItemClick("/history")}
                        selected={currentTab === "/history"}
                    >
                        History
                    </MenuItem>
                </Menu>
            </Grid>
        );
    }

    const renderLogoAndTypography = () => {
        return (
            <Grid sx={{ justifyContent: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Logo
                        src={
                            "https://pebs-cloud-mgmt.s3.amazonaws.com/pebs_logo.png"
                        }
                        alt="PEBS Logo Logo"
                    />
                </div>
            </Grid>
        );
    }

    const renderTabs = () => {
        return (
            <Grid xs={7} item>
                <Tabs
                    onChange={handleChange}
                    value={currentTab}
                    aria-label="Navigation Tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    <Tab label="Deductions" value="/deductions" component={RouterLink} to="/deductions" />
                    <Tab label="History" value="/history" component={RouterLink} to="/history" />
                </Tabs>
            </Grid>
        );
    }

    return (
        <>
        {route === 'authenticated' && (
            <>
                {isMobile ? (
                    <Box
                        sx={{ flexGrow: 1 }}
                    >
                        <AppBar
                            position="static"
                            sx={{ backgroundColor: theme.palette.secondary.main}}
                        >
                            <Toolbar>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                >
                                    {renderMenu()}
                                    {renderLogoAndTypography()}
                                    {renderLogout()}
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </Box>
                ) : (
                    <AppBar
                        position="static"
                        sx={{ backgroundColor: theme.palette.secondary.main}}
                    >
                        <Toolbar>
                            <Grid
                                container
                                justifyContent="space-between"
                                wrap='nowrap'
                            >
                                {renderLogoAndTypography()}
                                {renderTabs()}
                                {renderLogout()}
                            </Grid>
                        </Toolbar>
                    </AppBar>
                )}
            </>
        )}
        </>
    );
}