//App.js
import { Authenticator } from '@aws-amplify/ui-react';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login';
import { Header } from './components/Header';
import { Main } from './components/Main';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

function MyRoutes() {

  return (
      <BrowserRouter>
        <Header />
        <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to="/main"
                  replace={true} 
                />
              }
            />
            <Route
              index
              path="/main"
              element={
                <RequireAuth>
                  <Main />
                </RequireAuth>
              }
            />
            <Route
              path="/login" 
              element={
                <Login />
              } 
            />
        </Routes>
      </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;
