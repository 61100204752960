// components/Main.js
import { useAuthenticator } from '@aws-amplify/ui-react';

export function Main() {
    
    const { route } = useAuthenticator((context) => [context.route]);
    let message;
    if(route === 'authenticated') {
        message = PopulatePage();
    } else {
        message = 'Loading...';
    }
    return (
        message
    );
}

function PopulatePage() {

    return (
        <>
            This is my Main page.
        </>
    );

}