// components/Login.js
import { useEffect } from "react";
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { View } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Header } from "./LoginHeader";
import { Footer } from "./LoginFooter";
import { Flex } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/deductions';

  const components = {
    Header,
    Footer
  };

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <View className="auth-wrapper">
        <Flex
            justifyContent="center"
            justify-content="center"
            align-items="center"
            height="100vh"
        >
            <Authenticator 
                hideSignUp={false}
                components={components}
            >
            </Authenticator>
        </Flex>
    </View>
  );
}